@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

#root {
  animation: fadeIn .5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.errorInput {
  border: 1px solid rgb(239, 68, 68) !important;
}

/* General */
.poppins {
  font-family: "Poppins", sans-serif;
}

.montserrat {
  font-family: "Montserrat", sans-serif;
}

.open-sans {
  font-family: 'Open Sans', sans-serif;
}

.dashboard {
  width: calc(100% - 16rem);
  background-color: rgb(223, 223, 223)!important;
}

nav.close~.dashboard {
  left: 5rem;
  width: calc(100% - 5rem);
}

.dashboard .top-header {
  left: 16rem;
  width: calc(100% - 16rem);
  transition: all cubic-bezier(0.4, 0, 0.2, 1) 500ms;
}

nav.close~.dashboard .top-header {
  left: 5rem;
  width: calc(100% - 5rem);
}

@media (max-width: 600px) {
  .dashboard {
    width: 100%;
  }

  nav.close~.dashboard {
    left: 0rem;
    width: 100%;
  }

  .dashboard .top-header {
    left: 0rem;
    width: 100%;
  }

  nav.close~.dashboard .top-header {
    left: 0rem;
    width: 100%;
  }
}

.rdt_TableCol {
  border-right: 1px solid #d7d7d7;
  /* Agrega un borde a las celdas si lo deseas */
  font-size: .7rem;
  letter-spacing: .5px;
  font-weight: 600;
  color: #717171;
  background-color: #f1f1f1;
  display: flex;
  justify-content: center;
  text-transform: uppercase;
}

.rdt_TableHeadRow {
  min-height: 42px !important;
}

.rdt_TableRow {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  border-radius: 0px;
  border-bottom: .5px solid #e9e9e9 !important;
  padding: 8px 0;
}

.rdt_TableRow:hover {
  background-color: #f6f6f6;
}

.navbarC {
  background-color: #E4E3E2;
}

/* sobre escribiendo estilos (ancho de inputs) */
.rs-form-vertical .rs-form-group .rs-form-control-wrapper {
  display: block !important;
}

.rs-form-control-wrapper>.rs-input-number,
.rs-form-control-wrapper>.rs-input,
.rs-input-group {
  width: 100% !important;
}

.rs-input:disabled,
.rs-picker-disabled,
.rs-checkbox-disabled>.rs-checkbox-checker>label {
  color: #7f7f7f !important;
  opacity: 1 !important;
}

.rs-picker-toggle-wrapper {
  width: 100% !important;
}

.rs-form:not(.rs-form-inline) .rs-form-group:not(:last-child) {
  margin-bottom: 0px !important;
}

/* ------------------------------------------- */

/* Para navegadores basados en WebKit (Chrome, Safari) */
input[type="password"]::-webkit-contacts-auto-fill-button,
input[type="password"]::-webkit-credentials-auto-fill-button {
  display: none !important;
}

/* Para navegadores basados en Mozilla Firefox */
input[type="password"] {
  -moz-appearance: none;
  appearance: none;
}

/* Para navegadores basados en Microsoft Edge */
input[type="password"]::-ms-reveal {
  display: none;
}

/* Para navegadores basados en Microsoft Internet Explorer */
input[type="password"]::-ms-clear {
  display: none;
}

.choose::-webkit-file-upload-button {
  color: white;
  display: inline-block;
  background: #8d8d8d;
  border: none;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}

.choose::file-selector-button {
  color: white;
  display: inline-block;
  background: #8d8d8d;
  border: none;
  padding: 8px 15px;
  font-weight: 700;
  border-radius: 3px;
  white-space: nowrap;
  cursor: pointer;
  font-size: 10pt;
}



/* a todos los input quitar el autocomplete */
/* Ocultar las sugerencias de autocompletado */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px white inset;
  /* Ajusta el tamaño para ocultar las sugerencias */
}

/* Cambiar el fondo y el texto de las sugerencias de autocompletado */
input:-webkit-autofill {
  background-color: transparent;
  color: #000;
  /* Cambia el color del texto */
}


#nav-menu-aplication {
  background-color: rgb(219 234 254 / var(--tw-bg-opacity)) !important;
}

.checkbox-agente-retencion {
  margin-bottom: 0px !important;
}

.btnPrimary {
  background-color: #5DA5F9 !important;
  color: #ffffff !important;
}

.btnSecondary {
  background-color: #31af46 !important;
  color: #FFFFFF !important;
}

.btnDelete {
  background-color: #FC3D39 !important;
  color: #ffffff !important;
}

.btnUpdate {
  background-color: #4164DF !important;
  color: #ffffff !important;
}

.btnPdf {
  background-color: #ff4444 !important;
  color: #ffffff !important;
}

.btnExcel {
  background-color: #00C851 !important;
  color: #ffffff !important;
}

.btnTxt{
  background-color: #ffbb33 !important;
  color: #ffffff !important;
}

.btnEye{
  background-color: #030303 !important;
  color: #ffffff !important;
}

.btnCancelar {
  background-color: #030303 !important;
  color: #ffffff !important;
}

/* Animacion Text Swal */
.slide-vertical {
  overflow: hidden;
}

.slide-vertical ul,
.slide-vertical li {
  list-style: none;
}

.slide-vertical ul {
  width: 100%;
  height: 40px;
  position: relative;
  text-align: center;
}

.elements-2 ul {
  animation: slide2 5s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.elements-3 ul {
  animation: slide3 6s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite;
}

.slide-vertical li {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 200%;
  line-height: 40px;
  font-size: 20px;
}

.slide-vertical li:first-child {
  top: 100%;
}

.elements-2 li:last-child {
  top: 200%;
}

.elements-3 li:last-child {
  top: 300%;
}

@keyframes slide2 {
  0% {
    transform: translateY(0%);
  }

  5%,
  45% {
    transform: translateY(-100%);
  }

  55%,
  90% {
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(-300%);
  }
}

@keyframes slide3 {
  0% {
    transform: translateY(0%);
  }

  6%,
  30% {
    transform: translateY(-100%);
  }

  40%,
  60% {
    transform: translateY(-200%);
  }

  70%,
  90% {
    transform: translateY(-300%);
  }

  100% {
    transform: translateY(-400%);
  }
}

#preloader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99999;
  height: 100%;
  width: 100%;
  display: flex
}

.loader {
  opacity: .8;
  margin: auto;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative
}

.loader:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  border-radius: 50%;
  opacity: 0;
  animation: popin 1.5s linear infinite 0s
}

.loader:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: #000;
  border-radius: 50%;
  opacity: 0;
  animation: popin 1.5s linear infinite 0.5s
}

@keyframes popin {
  0% {
    opacity: 0;
    transform: scale(0)
  }

  1% {
    opacity: 0.1;
    transform: scale(0)
  }

  99% {
    opacity: 0;
    transform: scale(2)
  }

  100% {
    opacity: 0;
    transform: scale(0)
  }
}

/* Spinner Loader */
.spinner {
  width: 40px;
  height: 40px;
  position: relative;
  margin: auto;
}

.spinnerTick {
  margin: 50px auto;
  width: 40px;
  height: 20px;
  text-align: center;
  font-size: 12px;
}

.spinnerTick > div {
  background-color: #333;
  height: 100%;
  width: 6px;
  display: inline-block;
  
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinnerTick .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinnerTick .rect3 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

.spinnerTick .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinnerTick .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%, 40%, 100% { -webkit-transform: scaleY(0.4) }  
  20% { -webkit-transform: scaleY(1.0) }
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% { 
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }  20% { 
    transform: scaleY(1.0);
    -webkit-transform: scaleY(1.0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-out {
  animation: fadeOut 15000s ease-in-out;
}

.double-bounce1,
.double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #c9c9c9;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;

  -webkit-animation: sk-bounce 2.0s infinite ease-in-out;
  animation: sk-bounce 2.0s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1.0s;
  animation-delay: -1.0s;
}

@-webkit-keyframes sk-bounce {

  0%,
  100% {
    -webkit-transform: scale(0.0)
  }

  50% {
    -webkit-transform: scale(1.0)
  }
}

@keyframes sk-bounce {

  0%,
  100% {
    transform: scale(0.0);
    -webkit-transform: scale(0.0);
  }

  50% {
    transform: scale(1.0);
    -webkit-transform: scale(1.0);
  }
}

.form-input-error {
  border-radius: 8px;
  --tw-border-opacity: 1 !important;
  border-color: rgb(225 29 72/var(--tw-border-opacity)) !important;
  border-width: 2px !important;
}



/*  TABLE DURACELL */

table:not(.consolidado, .planActividades, .diario ) thead tr th {
  padding: 10px 20px;
}

table:not(.consolidado, .planActividades, .diario, .documentoReporteGlobal) tbody tr td {
  padding: 10px 2px;
}

.table-head {
  background-color: #d0023c !important;
  color: #FFF;
  font-weight: 500;
  font-size: 13px;
  text-transform: uppercase;
  font-family: 'Poppins';
  min-height: 40px !important;
}

.min-w-180 {
  min-width: 180px;
}

/* REPORTES */
.sticky-header {
  /* compatibilidad para firefox */
  position: -webkit-sticky;
  position: sticky;
  z-index: 1;
  left: 0;
  /* border: 1px solid #F2F2F2!important; */
}

/* aplucar border a todo el thead sus tr y th */
.table-create thead tr th {
  border: 1px solid #dcdada !important;
}

.rs-nav-item.rs-nav-item-disabled {
  pointer-events: auto!important;
  cursor: not-allowed!important;
  color: rgb(146, 146, 146)!important;
}

/* Pantallas de Errores */
.poppins {
  font-family: 'Poppins', sans-serif;
}

.container-error label {
  font-family: Arial, Helvetica, sans-serif;
}

.line-height-0 {
  line-height: 0;
}

.container-error {
  /* background: rgb(255,255,255); */
  /* background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(252,252,252,1) 100%); */
  width: 100vw;
  height: 100vh;
}

.container-error__card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 40px 0;
}

.container-error__card-content {
  position: relative;
  max-width: 400px;
  max-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
}

.container-error__card-icon {
  position: absolute;
  top: -150px;
}

.container-error__card-cod {
  font-size: 14rem;
  color: #5e5e5e;
}

.container-error__card-text {
  font-size: 3rem;
  padding-top: 50px;
}

.container-error__footer {
  position: absolute;
  text-align: center;
  bottom: 1rem;
  margin: auto;
  width: 100%;
}

.button_link {
  background-color: #008585;
  color: #fff;
  margin: auto 5px;
  padding: 4px 8px;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  transition: box-shadow 0.3s ease; /* Transici�n suave de la sombra */
}

  .button_link:hover {
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }

.container-error__footer-logo {
  height: 100px;
  margin: auto;
}

@media screen and (max-width: 600px) {
  .container-error__card-content {
      flex-direction: column;
      margin-top: 65px;
  }

  .container-error__card-icon {
      top: -170px;
  }

  .container-error__card-cod {
      font-size: 6rem;
  }

  .container-error__card-text {
      text-align: center;
      line-height: 3rem;
  }

  .container-error label {
      text-align: center;
      padding: 10px;
      line-height: 25px;
  }

  .container-error__card {
      gap: 20px 0;
  }

  .container-error__card-content {
      gap: 10px;
  }

  .container-error__card-icon-403 {
      top: -200px !important;
      width: 260px !important;
  }

  .container-error__card-icon-404 {
      top: -250px !important;
  }

  .container-error__card-icon-500 {
      top: -260px !important;
  }
}